<template>
<!-- 商品分类 -->
<div class="whiteBg">
  <template v-if="roleBtn[0].power">
    <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
  </template>
  <div class="table_cont">
    <el-table
      ref="tableDom"  lazy :load="load"
      :header-cell-style="{background:'#EBF2FE'}"
      :data="comsortList"
      :key="symbolKey"
      style="width: 100%"
      row-key="gcid"
      :tree-props="{children: 'gtid_list', hasChildren: 'hasChildren'}">
      <el-table-column
        label="序号"
        width="200"
        type="index">
        <template slot-scope="scope">
          <span>{{scope.row.index}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="分类名称"
        prop="gcname"
        width="500">
      </el-table-column>
      <el-table-column
        label="展示"
        prop="gcisok">
        <template slot-scope="scope">
          <el-switch
            v-if="scope.row.gcisok === true"
            :value="scope.row.gcisok"
            active-color="#13ce66"
            inactive-color="#A5B7CC"
            @change="changeSwich(scope.row)">
          </el-switch>
          <el-switch
            v-if="scope.row.gcisok === false"
            :value="scope.row.gcisok"
            active-color="#13ce66"
            inactive-color="#A5B7CC"
            @change="changeSwich(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        width="200"
        label="操作">
        <template slot-scope="scope">
          <template v-if="roleBtn[2].power">
            <el-button type="text" size="small" @click="changeAdd(scope.row)" v-if="scope.row.index">新建下级</el-button>
            <el-button type="text" size="small" @click="showEdit(scope.row)" v-if="scope.row.index">编辑</el-button>
            <el-button type="text" size="small" @click="changeEdit(scope.row)" v-if="!scope.row.index">编辑</el-button>
            <el-button type="text" size="small" style="color: red;" @click="handDelte(scope.row)" v-if="!scope.row.index">删除</el-button>
          </template>
          <template v-if="roleBtn[1].power">
            <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)" v-if="scope.row.index">删除</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
    @current-change="pageChange"
    :current-page="page"
    :page-sizes="[10]"
    :page-size="size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    class="settingPage">
  </el-pagination>

   <!-- 一级分类新增编辑弹窗 -->
  <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
    <el-form :model="unitfrom" :rules="formRules" ref="unitFrom">
      <el-form-item label="分类名称" prop="gcname">
        <el-input v-model="unitfrom.gcname"  placeholder="请输入分类名称"></el-input>
      </el-form-item>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="FromCancel">取消</div>
      <div class="settingConfirm" @click="FromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 二级分类新增编辑弹窗 -->
  <el-dialog :title="dialogType" :visible.sync="typeShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="typeFromCancel">
    <el-form :model="typefrom" :rules="typeformRules" ref="typeFrom">
      <el-form-item label="分类名称" prop="gcname">
        <el-input v-model="typefrom.gcname"  placeholder="请输入分类名称"></el-input>
      </el-form-item>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="typeFromCancel">取消</div>
      <div class="settingConfirm" @click="typeFromConfirm">确定</div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { goodsortGet, goodlevelAdd, goodlevelUpdate, goodlevelDelete, goodtypeAdd, goodtypeUpdate, goodtypeDelete, goodlevelIsok, goodtypeIsok } from '@/api/commodity'
export default {
  name: 'commoditySort',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      comsortList: [],
      gcisok: true,
      fromShow: false,
      unitfrom: {
        gcname: '',
        gcid: ''
      },
      formRules: {
        gcname: [
          { required: true, trigger: 'blur', message: '分类名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      dialogName: '',
      dialogType: '',
      typeShow: false,
      typefrom: {
        gcname: '',
        gtid: '',
        gcid: '',
      },
      typeformRules: {
        gcname: [
          { required: true, trigger: 'blur', message: '分类名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      symbolKey:'',	// 不同的key
    }
  },
  created (){
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      goodsortGet(params).then(res => {
        if (res.status === 1) {
          res.data.data.forEach((item, index) => {
            item.index = index + 1
            item.id = item.gcid
            if (item.gtid_list.length !== 0) {
              item.hasChildren = true
            } else {
              item.hasChildren = false
            }
            if (item.gcisok == false) {
              item.gtid_list.gcisok = false
            }
          })
          this.symbolKey = Symbol(new Date().toString())
          this.comsortList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.comsortList = []
          this.total = 0
        }
      })
    },
    load(tree, treeNode, resolve) {
      resolve(tree.gtid_list)
    },
    //是否展示
    changeSwich (row) {
      if (row.index) {
        this.$confirm(`确定执行${!row.gcisok ? "展示" : "不展示"}操作, 是否继续？`, "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          const params = {
            gcid: row.gcid,
            gcisok: row.gcisok,
          }
          goodlevelIsok(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      } else {
        this.$confirm(`确定执行${!row.gcisok ? "展示" : "不展示"}操作, 是否继续？`, "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          const params = {
            gtid: row.gcid,
            gtisok: row.gcisok,
          }
          goodtypeIsok(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    // 一级新增
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.unitfrom = {}
    },
    // 一级编辑
    showEdit (row) {
      this.unitfrom = { ...row }
      this.fromShow = true
      this.dialogName = '编辑'
    },
    // 一级新增 编辑 提交
    FromConfirm () {
      this.$refs.unitFrom.validate((valid) => {
        if (!valid) return
        if (this.dialogName === '新增') {
          goodlevelAdd({gcname: this.unitfrom.gcname}).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            }
          })
        } else if (this.dialogName === '编辑') {
          const params = {
            gcid: this.unitfrom.gcid,
            gcname: this.unitfrom.gcname,
          }
          goodlevelUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            }
          })
        }
      })
    },
    FromCancel () {
      this.$refs.unitFrom.resetFields()
      this.fromShow = false
    },
    // 创建下级 二级新增
    changeAdd (row) {
      this.typeShow = true
      this.dialogType = '新增'
      // this.typefrom = {...row}
      this.typefrom.gcid = row.gcid
    },
    // 二级编辑
    changeEdit (row) {
      this.typeShow = true
      this.dialogType = '编辑'
      this.typefrom = {...row}
    },
    typeFromConfirm () {
      this.$refs.typeFrom.validate((valid) => {
        if (!valid) return
        if (this.dialogType === '新增') {
          const params = {
            gcid: this.typefrom.gcid,
            gtname: this.typefrom.gcname
          }
          goodtypeAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.typeFromCancel()
              this.init()
            }
          })
        } else if (this.dialogType === '编辑') {
          const params = {
            gtname: this.typefrom.gcname,
            gtid: this.typefrom.gcid
          }
          goodtypeUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.typeFromCancel()
              this.init()
            }
          })
        }
      })
    },
    typeFromCancel () {
      this.$refs.typeFrom.resetFields()
      this.typeShow = false
    },
    // 一级删除
    handDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        goodlevelDelete({ gcid: row.gcid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 二级删除
    handDelte (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        goodtypeDelete({ gtid: row.gcid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.$nextTick(() => {
              this.init()
            })
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
  }
}
</script>

<style scoped>
.row-expand-cover .el-table__expand-icon {
  visibility: hidden;
}
</style>
